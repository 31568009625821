import {Component, Input} from '@angular/core';

@Component({
  selector: 'ifw-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent {

  @Input()
  public title: string = '';

}
