import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LocalesEnum} from './locales.enum';
import {OnAppInit} from '../../../shared/services/service-initializer';

@Injectable()
export class LocalesService implements OnAppInit {

  constructor(
    private readonly _translateService: TranslateService,
  ) {
  }

  public get currentLocale(): LocalesEnum {
    if (!(Object.values(LocalesEnum) as Array<string>).includes(this._translateService.currentLang)) {
      throw new Error(`Current language '${this._translateService.currentLang}' is not a supported locale`);
    }
    return this._translateService.currentLang as LocalesEnum;
  }

  public onAppInit(): void {
    this._translateService.langs = Object.values(LocalesEnum);
    this._translateService.defaultLang = LocalesEnum.EN;
    console.info('Navigator language', navigator.language);
    let locale = navigator.language;
    if (locale.includes('-')) {
      locale = locale.substring(0, navigator.language.indexOf('-'));
    }
    if (!this._translateService.langs.includes(locale)) {
      locale = this._translateService.defaultLang;
    }
    this.internalLocaleUpdate(locale as LocalesEnum);

    this._translateService.stream('TITLE').subscribe((title: string) => document.title = title);
  }

  public changeLocale(locale: LocalesEnum): void {
    if (locale === this.currentLocale) {
      return;
    }
    this.internalLocaleUpdate(locale);
  }

  private internalLocaleUpdate(locale: LocalesEnum): void {
    this._translateService.use(locale);
    document.documentElement.setAttribute('lang', locale);
    console.info('Using locale', locale);
  }
}
