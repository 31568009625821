import {Component, OnInit} from '@angular/core';
import {LoginService} from './services/login/login.service';

@Component({
  selector: 'ifw-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  public password = '';
  public loginFailed = false;

  constructor(
    private readonly _loginService: LoginService,
  ) {
  }

  public get loginInProgress(): boolean {
    return this._loginService.requestProcessing;
  }

  public get loginServerError(): boolean {
    return this._loginService.serverError;
  }

  public ngOnInit(): void {
  }

  public async login(password?: string): Promise<void> {
    this.loginFailed = false;
    const success = await this._loginService.login(password ?? this.password);
    this.loginFailed = !success;
  }
}
