import {Component, OnInit} from '@angular/core';
import {SongService} from './services/song/song.service';
import {Song} from './services/song/song';

@Component({
  selector: 'ifw-ceremony',
  templateUrl: './ceremony.component.html',
  styleUrls: ['./ceremony.component.scss'],
})
export class CeremonyComponent implements OnInit {

  constructor(
    private readonly _songService: SongService,
  ) {
  }

  public get songs(): Array<Song> {
    return this._songService.songs;
  }

  ngOnInit(): void {
  }

}
