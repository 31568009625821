import {Component} from '@angular/core';
import {SongService} from '../../services/song/song.service';
import {Song} from '../../services/song/song';
import {Router} from '@angular/router';

@Component({
  selector: 'ifw-song-page',
  templateUrl: './song-page.component.html',
  styleUrls: [
    '../song.common.scss',
    './song-page.component.scss',
  ],
})
export class SongPageComponent {

  public displayCounterpoint: boolean = false;

  constructor(
    private readonly _songService: SongService,
    private readonly _router: Router,
  ) {
  }

  public get route(): string {
    const urlElements = this._router.url.split('/');
    return urlElements[urlElements.length - 1];
  }

  public get song(): Song {
    const song = this._songService.songs.find((song: Song) => song.route === this.route);
    if (!song) {
      throw new Error(`Song '${this.route}' does not exist`);
    }
    return song;
  }

  public get assetsFolder(): string {
    return 'assets/songs/' + this.route;
  }

}
