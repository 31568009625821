import {Injectable} from '@angular/core';
import {AccessEnum} from './access-enum';

@Injectable()
export class StateService {

  public access = AccessEnum.NONE;

  constructor() {
  }
}
