<ifw-top-bar-layout [homeSelected]="true">
  <div class="contentWrapper">

    <ifw-horizontal-divider [configuration]="['thin','thick']"></ifw-horizontal-divider>

    <div class="content">

      <h1 class="flourish title">
        {{ 'HOME.TITLE' | translate }}
      </h1>

      <h2 class="flourish date">
        {{ 'HOME.DATE' | translate }}
      </h2>

    </div>

    <ifw-horizontal-divider [configuration]="['thick','thin']"></ifw-horizontal-divider>

  </div>
</ifw-top-bar-layout>
