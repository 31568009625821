<ifw-top-bar-layout [photosSelected]="true">
  <div class="contentWrapper">

    <ifw-horizontal-divider [configuration]="['thin','thick']"></ifw-horizontal-divider>

    <div class="content">

      <h2 class="flourish title">
        {{ 'PHOTOS.TITLE' | translate }}
      </h2>

      <div class="officialWrapper">
        <h3>
          {{ 'PHOTOS.OFFICIAL.TITLE' | translate }}
        </h3>
        <p class="officialInstructions">
          {{ 'PHOTOS.OFFICIAL.INSTRUCTIONS' | translate }}
        </p>
        <p class="officialInstructions">
          {{ 'PHOTOS.OFFICIAL.DOWNLOAD_INSTRUCTIONS' | translate:{code: downloadCode} }}
        </p>
        <a ifw-link
          class="officialLink"
          href="https://gabriellescherrerphotographie.pixieset.com/guestlogin/florenceetivan/?return=%2Fflorenceetivan%2F"
          target="_blank">
          {{ 'PHOTOS.OFFICIAL.LINK' | translate }}
          <ifw-icon iconName="external-link">
          </ifw-icon>
        </a>
      </div>

      <a ifw-link
         href="https://drive.google.com/drive/folders/1E7zpgb4FrysulsSLXv6OpdNoSO8C0PuE?usp=sharing"
         target="_blank">
        {{ 'PHOTOS.GUESTS_DRIVE' | translate }}
        <ifw-icon iconName="external-link">
        </ifw-icon>
      </a>

    </div>

    <ifw-horizontal-divider [configuration]="['thick','thin']"></ifw-horizontal-divider>

  </div>
</ifw-top-bar-layout>
