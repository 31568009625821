import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {WorkInProgressComponent} from './components/work-in-progress/work-in-progress.component';
import {NoiseComponent} from './components/noise/noise.component';
import {HorizontalDividerComponent} from './components/horizontal-divider/horizontal-divider.component';
import {ButtonComponent} from './components/buttons/button/button.component';
import {TextFieldComponent} from './components/fields/text-field/text-field.component';
import {TopBarComponent} from './components/top-bar/top-bar.component';
import {TopBarTabComponent} from './components/top-bar/top-bar-tab/top-bar-tab.component';
import {BurgerMenuButtonComponent} from './components/top-bar/burger-menu-button/burger-menu-button.component';
import {IconComponent} from './components/icon/icon.component';
import {GoogleMapComponent} from './components/google-map/google-map.component';
import {SectionComponent} from './components/section/section.component';
import {LinkComponent} from './components/link/link.component';
import {NumberFieldComponent} from './components/fields/number-field/number-field.component';
import {SlideToggleComponent} from './components/slide-toggle/slide-toggle.component';
import {TextAreaComponent} from './components/fields/text-area/text-area.component';
import {ExpansionPanelComponent} from './components/expansion-panel/expansion-panel.component';
import {IconButtonComponent} from './components/buttons/icon-button/icon-button.component';
import {CardComponent} from './components/card/card.component';
import { AudioComponent } from './components/audio/audio.component';


@NgModule({
  declarations: [
    PageNotFoundComponent,
    WorkInProgressComponent,
    NoiseComponent,
    HorizontalDividerComponent,
    ButtonComponent,
    TextFieldComponent,
    TopBarComponent,
    TopBarTabComponent,
    BurgerMenuButtonComponent,
    IconComponent,
    GoogleMapComponent,
    SectionComponent,
    LinkComponent,
    NumberFieldComponent,
    SlideToggleComponent,
    TextAreaComponent,
    ExpansionPanelComponent,
    IconButtonComponent,
    CardComponent,
    AudioComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    TranslateModule,
  ],
  exports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    TranslateModule,
    WorkInProgressComponent,
    NoiseComponent,
    HorizontalDividerComponent,
    ButtonComponent,
    TextFieldComponent,
    TopBarComponent,
    TopBarTabComponent,
    BurgerMenuButtonComponent,
    IconComponent,
    GoogleMapComponent,
    SectionComponent,
    LinkComponent,
    NumberFieldComponent,
    SlideToggleComponent,
    TextAreaComponent,
    ExpansionPanelComponent,
    IconButtonComponent,
    CardComponent,
    AudioComponent,
  ],
})
export class SharedModule {
}
