import {Component} from '@angular/core';

@Component({
  selector: 'ifw-noise',
  templateUrl: './noise.component.html',
  styleUrls: ['./noise.component.scss'],
})
export class NoiseComponent {

}
