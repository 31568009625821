<ifw-card>
  <div class="audioWrapper">
    <button ifw-icon-button
            [disabled]="disabled"
            (click)="playPause()">
      <ifw-icon [iconName]="paused ? 'play' : 'pause'"></ifw-icon>
    </button>
    <div class="trackWrapper">
      <p>{{ label }}</p>
      <div #track
           class="track"
           (click)="onTrackCLick($event)"
           (pointermove)="onTrackPointerMove($event)">
        <div *ngFor="let bufferedPercentage of bufferedPercentages"
             class="buffer"
             [style.transition-duration]="updateBufferTimingInMs + 'ms'"
             [style.left.%]="bufferedPercentage.start"
             [style.width.%]="bufferedPercentage.duration">
        </div>
        <div class="progress"
             [style.transition-duration]="updateProgressTimingInMs + 'ms'"
             [style.width.%]="currentPercentage">
        </div>
        <div class="cursor"
             [style.width.%]="cursorPercentage">
        </div>
      </div>
    </div>
  </div>
</ifw-card>
