import {Component, ElementRef, Input} from '@angular/core';

@Component({
  selector: 'a[ifw-top-bar-tab], p[ifw-top-bar-tab]',
  templateUrl: './top-bar-tab.component.html',
  styleUrls: ['./top-bar-tab.component.scss'],
})
export class TopBarTabComponent {

  private _ifSelected = false;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
  ) {
  }

  public get ifSelected(): boolean {
    return this._ifSelected;
  }

  @Input()
  public set ifSelected(value: any) {
    this._ifSelected = typeof value === 'boolean' ? value : value !== undefined;
    if (this._ifSelected !== this._elementRef.nativeElement.hasAttribute('ifw-selected')) {
      this._elementRef.nativeElement.toggleAttribute('ifw-selected', true);
    }
  }
}
