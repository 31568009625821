import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import version from '../package.json';

Sentry.init({
  dsn: 'https://c572057fe5904572a7c7dd42f1d8ab47@o4504759074553856.ingest.sentry.io/4504759084449792',
  environment: environment.name,
  release: version['version'],
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => {
    Sentry.captureException(err);
    console.error(err);
  });
