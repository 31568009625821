import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterOutlet} from '@angular/router';

import * as Sentry from '@sentry/angular-ivy';

import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {AppRoutingModule} from './app-routing.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SharedModule} from './shared/shared.module';
import {LoginModule} from './pages/login/login.module';
import {HomeModule} from './pages/home/home.module';
import {CeremonyModule} from './pages/ceremony/ceremony.module';
import {ReceptionModule} from './pages/reception/reception.module';
import {PhotosModule} from './pages/photos/photos.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        deps: [HttpClient],
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, 'assets/i18n/', '.json'),
      },
    }),
    RouterOutlet,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    LoginModule,
    HomeModule,
    CeremonyModule,
    ReceptionModule,
    PhotosModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
