import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {LocalesService} from './services/locales/locales.service';
import {serviceInitializer} from '../shared/services/service-initializer';
import {StateService} from './services/state/state.service';
import {TopBarLayoutComponent} from './components/top-bar-layout/top-bar-layout.component';
import {RouterModule} from '@angular/router';
import {AccessGrantedDirective} from './directives/access-granted.directive';
import {ApiService} from './services/api/api.service';
import {PracticalInformationComponent} from './components/practical-information/practical-information.component';
import {
  PracticalInformationSectionComponent,
} from './components/practical-information/practical-information-section/practical-information-section.component';
import {AccessEqualsDirective} from './directives/access-equals.directive';


@NgModule({
  declarations: [
    TopBarLayoutComponent,
    AccessGrantedDirective,
    PracticalInformationComponent,
    PracticalInformationSectionComponent,
    AccessEqualsDirective,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
  ],
  providers: [
    LocalesService,
    serviceInitializer(LocalesService),
    StateService,
    ApiService,
  ],
  exports: [
    SharedModule,
    TopBarLayoutComponent,
    AccessGrantedDirective,
    PracticalInformationComponent,
    PracticalInformationSectionComponent,
    AccessEqualsDirective,
  ],
})
export class CoreModule {
}
