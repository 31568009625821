import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ifw-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent {

  @ViewChild('frame', {static: true})
  private _frame!: ElementRef<HTMLIFrameElement>;

  constructor(
    private readonly _translateService: TranslateService,
  ) {
  }

  @Input()
  public set parameters(value: string) {
    this._frame.nativeElement.src = `https://www.google.com/maps/embed?${value}`;
    console.log('GMap src', this._frame.nativeElement.src);
  }

}
