<form #loginForm="ngForm"
      class="loginForm"
      (ngSubmit)="login()">

  <ifw-horizontal-divider [configuration]="['thin','thick']"></ifw-horizontal-divider>

  <div class=content>

    <h1 class="flourish loginTitle">
      {{ 'LOGIN.TITLE' | translate }}
    </h1>

    <p class="loginLabel">{{ 'LOGIN.TEXT' | translate }}</p>

    <input ifw-text-field
           type="text"
           id="password"
           name="password"
           class="password"
           required
           autocomplete="on"
           [hidden]="false"
           [placeholder]="'LOGIN.PASSWORD_PLACEHOLDER' | translate"
           [disabled]="loginInProgress"
           [(ngModel)]="password">

    <button ifw-button
            type="submit"
            [disabled]="!loginForm.form.valid || loginInProgress">
      {{ 'LOGIN.CONNECT' | translate }}
    </button>

    <div class="loginFailed"
         [class.visible]="loginFailed">
      <p>{{ 'LOGIN.' + (loginServerError ? 'SERVER_ERROR' : 'FAILED') | translate }}</p>
    </div>
  </div>

  <ifw-horizontal-divider [configuration]="['thick','thin']"></ifw-horizontal-divider>

</form>
