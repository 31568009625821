import {Injectable} from '@angular/core';
import {AccommodationInterface} from './accommodation.interface';

@Injectable()
export class AccommodationService {

  public readonly hotels: Array<AccommodationInterface> = [
    {
      place: 'Hôtel du Centre',
      distance: '5km',
      phone: '02 47 29 21 37',
      address: '6 place de la République\n' +
        '37370 St Paterne Racan',
      capacity: '9 chambres\n' +
        '(+1 communicante)',
    },
    {
      place: 'Hôtel Louise de la Vallière',
      distance: '8km',
      phone: '02 47 24 00 24',
      address: 'Place de la Gare\n' +
        '37330 Château la Vallière',
      capacity: '14 chambres\n' +
        '1 à 4 personnes',
    },
    {
      place: 'Hôtel Le Grand Cerf',
      distance: '15km',
      phone: '02 47 24 11 06',
      address: 'La Porrerie – route du Lude\n' +
        '37330 Villiers au Bouin',
      capacity: '20 chambres',
    },
    {
      place: 'Hôtel de la Gare',
      distance: '20km',
      phone: '02 43 44 00 14',
      address: '170 avenue Jean Jaurès\n' +
        '72500 Château du Loir',
      capacity: '14 chambres',
    },
    {
      place: 'Hôtel des Voyageurs',
      distance: '20km',
      phone: '02 43 44 00 31',
      address: '168 avenue Jean Jaurès\n' +
        '72500 Château du Loir',
      capacity: '9 chambres',
    },
  ];

  public readonly bedAndBreakfasts: Array<AccommodationInterface> = [
    {
      place: 'Mme Bouchet',
      distance: '9km',
      phone: '02 47 42 61 68\n' +
        '06 21 24 66 66',
      address: 'La Vallée\n' +
        '37370 St Christophe/le Nais',
      capacity: '2 chambres\n' +
        '5/6 personnes',
    },
    {
      place: 'Mr et Mme Pleynet',
      distance: '20km',
      phone: '02 43 44 31 40',
      address: 'Les Vieilles Maisons\n' +
        '72500 Vaas',
      capacity: '4 chambres\n' +
        '9 personnes',
    },
    {
      place: 'Mme Bretonneau',
      distance: '12km',
      phone: '02 43 44 59 79',
      address: 'Le Moulin du Prieuré\n' +
        '72500 Dissay ss Courcillon',
      capacity: '2 chambres\n' +
        '+ 2 studios\n' +
        '4/7 personnes',
    },
    {
      place: 'Mme Charles',
      distance: '15km',
      phone: '02 43 79 23 84',
      address: 'Les Sablons\n' +
        '72500 Vouvray sur Loir',
      capacity: '3 chambres',
    },
    {
      place: 'Mme Besnier',
      distance: '12km',
      phone: '02 47 24 58 91',
      address: 'Le Clos de Launay\n' +
        '37330 Souvigné',
      capacity: '8 chambres\n' +
        '2 à 4 personnes',
    },
    {
      place: 'Mme Gaudin',
      distance: '17km',
      phone: '02 47 24 65 26',
      address: 'Le Tertre\n' +
        '37330 Channay s/ lathan',
      capacity: '3 chambres\n' +
        'à la ferme',
    },
    {
      place: 'Mme Cormery',
      distance: '14km',
      phone: '02 47 24 44 76',
      address: 'Le Château du Bois\n' +
        '37370 Neuvy le Roi',
      capacity: '4 chambres',
    },
    {
      place: 'Mme Defond',
      distance: '15km',
      phone: '02 47 24 90 88',
      address: 'Domaine de la Bergerie\n' +
        '37330 Braye sur Maulne',
      capacity: '5 chambre +\n' +
        '1 gîte 15 pers\n' +
        '1 gîte 8 pers\n' +
        '1 gîte 5 pers',
    },
    {
      place: 'N. Felten\n' +
        'N. Bilbault',
      distance: '20km',
      phone: '02 47 37 08 62',
      address: 'Le Grand Launay\n' +
        '37360 Semblancay',
      capacity: '3 ch\n' +
        '8/9 pers',
    },
    {
      place: 'Mr Berge',
      distance: '17km',
      phone: '02 47 24 63 65',
      address: 'La Galechère II\n' +
        '37330 Courcelles de Touraine',
      capacity: '1 ch 4pers\n' +
        '1 ch 2 pers',
    },
  ];

  public readonly gites: Array<AccommodationInterface> = [
    {
      place: 'La Ferme De Racan\n' +
        '****',
      distance: '6km',
      phone: '06 79 77 93 69\n' +
        'www.locationgiteracan.com\n' +
        'lesmaisonsdyves@gmail.com',
      address: 'Mr Yves Lapeyre\n' +
        'Château de la Roche Racan\n' +
        '37370 St Paterne Racan',
      capacity: 'Trois espaces Différents\n' +
        '30 places',
    },
    {
      place: 'Le Logis de la Garenne',
      distance: '3.5km',
      phone: '02 44 52 10 50\n' +
        '06 66 82 37 76\n' +
        'Aboutard.cc@gmail.com',
      address: 'Mr Boutard\n' +
        '37370 St Aubin le Dépeint',
      capacity: '5 chambres\n' +
        '12 personnes',
    },
    {
      place: 'Le Gite du vieux Chataîgnier',
      distance: '11km',
      phone: '02 43 44 82 26',
      address: 'Mr Devalée /Mme Sénéchal\n' +
        '72500 St Pierre de Chevillé',
      capacity: '2 chambres\n' +
        '4/6personnes',
    },
    {
      place: 'La Soudairie',
      distance: '15km',
      phone: '02 47 27 56 10',
      address: '37370 Bueil en Touraine',
      capacity: '3 chambres\n' +
        '8 personnes',
    },
    {
      place: 'Le Tuffeau',
      distance: '15km',
      phone: '06 25 06 22 49',
      address: '72500 Chenu',
      capacity: '9 places',
    },
    {
      place: 'La Charantonnière',
      distance: '8km',
      phone: '02 47 27 56 10',
      address: '37330 Château la Vallière',
      capacity: '2 chambres\n' +
        '4 personnes',
    },
    {
      place: 'Vaujours',
      distance: '8km',
      phone: '02 47 27 56 10',
      address: '37330 Château la Vallière',
      capacity: '2 chambres\n' +
        '4 personnes',
    },
    {
      place: 'La Perrée',
      distance: '8km',
      phone: '02 47 27 56 10',
      address: '37330 Château la Vallière',
      capacity: '4 chambres\n' +
        '8 personnes',
    },
    {
      place: 'La Raberie',
      distance: '16km',
      phone: '02 47 24 54 84',
      address: '37360 Neuillé Pont Pierre',
      capacity: '3 chambres\n' +
        '5 personnes',
    },
    {
      place: 'Appart Étape',
      distance: '12km',
      phone: '02 43 44 09 06',
      address: '72500 Dissay sous\n' +
        'Courcillon',
      capacity: 'Chambres et studios',
    },
    {
      place: 'Le Rouvre',
      distance: '15km',
      phone: '02 47 24 40 59',
      address: '37370 Neuvy le Roi',
      capacity: '3 chambres\n' +
        '6 personnes',
    },
    {
      place: 'La Boulerie',
      distance: '9km',
      phone: '02 47 27 56 10',
      address: '37330 Villiers au Bouin',
      capacity: '2 chambres\n' +
        '4 personnes',
    },
  ];

  constructor() {
  }
}
