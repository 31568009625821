import {Component} from '@angular/core';

@Component({
  selector: 'ifw-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {

}
