import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter, HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {filter, interval, Subscription} from 'rxjs';

export interface AudioRange {
  start: number,
  duration: number,
}

@Component({
  selector: 'ifw-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss'],
})
export class AudioComponent implements OnInit, OnDestroy {

  public static readonly UPDATE_TIMING_IN_MS: number = 500;

  @Input()
  @HostBinding('class.disabled')
  public disabled: boolean = true;
  @Output()
  public readonly disabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public label: string = '';

  @ViewChild('track', {static: true})
  private _track?: ElementRef<HTMLDivElement>;

  private readonly _audio: HTMLAudioElement = new Audio();

  private _updateProgressSubscription: Subscription | null = null;
  private _currentPercentage: number = 0;

  private _cursorPercentage: number = 0;

  private _updateBufferSubscription: Subscription | null = null;
  private _bufferedPercentages: Array<AudioRange> = [];

  private _immediateProgressUpdate: boolean = false;
  private _immediateBufferUpdate: boolean = false;

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  public get src(): string {
    return this._audio.src;
  }

  @Input()
  public set src(value: string) {
    this._audio.src = value;
    this.updateAudio();
  }

  public get paused(): boolean {
    return this._audio.paused;
  }

  public get currentPercentage(): number {
    return this._currentPercentage;
  }

  public get cursorPercentage(): number {
    return this._cursorPercentage;
  }

  public get bufferedPercentages(): Array<AudioRange> {
    return this._bufferedPercentages;
  }

  public get updateProgressTimingInMs(): number {
    return this._immediateProgressUpdate ? 0 : AudioComponent.UPDATE_TIMING_IN_MS;
  }

  public get updateBufferTimingInMs(): number {
    return this._immediateBufferUpdate ? 0 : AudioComponent.UPDATE_TIMING_IN_MS;
  }

  private get currentTime(): number {
    return this._audio.currentTime;
  }

  private get duration(): number {
    return this._audio.duration;
  }

  private get bufferedTimes(): Array<AudioRange> {
    const ranges = [];
    for (let i = 0; i < this._audio.buffered.length; i++) {
      ranges.push({
        start: this._audio.buffered.start(i),
        duration: this._audio.buffered.end(i) - this._audio.buffered.start(i),
      });
    }
    return ranges;
  }

  ngOnInit(): void {
    this._audio.addEventListener('error', this.onError.bind(this));
    this._audio.addEventListener('canplay', this.onCanPlay.bind(this));
    this._audio.addEventListener('ended', this.onEnded.bind(this));
  }

  ngOnDestroy(): void {
    if (!this.paused) {
      this._audio.pause();
    }
    this._audio.removeEventListener('error', this.onError.bind(this));
    this._audio.removeEventListener('canplay', this.onCanPlay.bind(this));
    this._audio.removeEventListener('ended', this.onEnded.bind(this));

    this._updateProgressSubscription?.unsubscribe();
    this._updateBufferSubscription?.unsubscribe();
  }

  public playPause(): void {
    if (this.disabled) {
      return;
    }
    if (this.paused) {
      if (this._audio.ended) {
        this.immediateCurrentTimeUpdate(0);
      }
      this._audio.play();
    } else {
      this._audio.pause();
    }
  }

  public onTrackCLick(event: MouseEvent): void {
    if (this.disabled) {
      return;
    }
    const playing = !this.paused;

    this._audio.currentTime = this.eventToTime(event);

    this.immediateCurrentTimeUpdate(this.currentTime);

    if (playing) {
      this._audio.play();
    }
  }

  public onTrackPointerMove(event: PointerEvent): void {
    const cursorTime = this.eventToTime(event);
    this._cursorPercentage = this.timeToPercentage(cursorTime);
  }

  private updateAudio(): void {
    if (this.src === '') {
      this.updateDisabled(true);
      return;
    }

    this._audio.src = this.src;
    this._audio.load();
  }

  private updateDisabled(value: boolean): void {
    if (this.disabled === value) {
      return;
    }

    this.disabled = value;
    this.disabledChange.emit(value);

    this._updateProgressSubscription?.unsubscribe();
    this._updateProgressSubscription = null;
    this._updateBufferSubscription?.unsubscribe();
    this._updateBufferSubscription = null;
    if (!this.disabled) {
      this._updateProgressSubscription = interval(AudioComponent.UPDATE_TIMING_IN_MS)
        .pipe(
          filter(() => !this.paused),
        ).subscribe(() => {
          this._currentPercentage = this.timeToPercentage(this.currentTime);
        });
      this._updateBufferSubscription = interval(AudioComponent.UPDATE_TIMING_IN_MS)
        .subscribe(() => {
          const bufferedPercentages = this.bufferedTimes.map(range => {
            return {
              start: this.timeToPercentage(range.start),
              duration: this.timeToPercentage(range.duration),
            };
          });
          for (let i = 0; i < Math.min(this._bufferedPercentages.length, bufferedPercentages.length); i++) {
            this._bufferedPercentages[i].start = bufferedPercentages[i].start;
            this._bufferedPercentages[i].duration = bufferedPercentages[i].duration;
          }
          if (bufferedPercentages.length !== this._bufferedPercentages.length) {
            this._immediateBufferUpdate = true;
            setTimeout(() => this._immediateBufferUpdate = false, 50);
          }
          if (bufferedPercentages.length > this._bufferedPercentages.length) {
            for (let i = this._bufferedPercentages.length; i < bufferedPercentages.length; i++) {
              this._bufferedPercentages.push(bufferedPercentages[i]);
            }
          } else if (this._bufferedPercentages.length > bufferedPercentages.length) {
            this._bufferedPercentages.splice(bufferedPercentages.length);
          }
        });
    }
  }

  private timeToPercentage(time: number): number {
    return (time / this.duration) * 100;
  }

  private eventToTime(event: MouseEvent): number {
    const rect = this._track?.nativeElement.getBoundingClientRect() ?? {
      x: 0,
      width: 1,
    };
    return ((event.clientX - rect.x) / rect.width) * this.duration;
  }

  private immediateCurrentTimeUpdate(value: number): void {
    this._immediateProgressUpdate = true;
    this._currentPercentage = this.timeToPercentage(value);
    setTimeout(() => this._immediateProgressUpdate = false, 50);
  }

  private onError(error: ErrorEvent): void {
    console.error('Error when loading', this.src, error);
    this.updateDisabled(true);
  }

  private onCanPlay(event: Event): void {
    console.log('Can play', this.src);
    this.updateDisabled(false);
  }

  private onEnded(event: Event): void {
    console.log('Ended', this.src);
    this._currentPercentage = 100;
  }
}
