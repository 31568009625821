import {Component, ElementRef, HostBinding, Input, ViewChild} from '@angular/core';

@Component({
  selector: 'ifw-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent {

  @Input()
  @HostBinding('class.expanded')
  public expanded: boolean = false;

  @ViewChild('body', {static: true})
  private _body!: ElementRef<HTMLDivElement>;

  @ViewChild('subheader', {static: true})
  private _subheader!: ElementRef<HTMLDivElement>;

  public get bodyHeight(): number {
    return this.expandableContainerHeight(this._body.nativeElement);
  }

  public get subheaderHeight(): number {
    return this.expandableContainerHeight(this._subheader.nativeElement);
  }

  public expandCollapse(): void {
    this.expanded = !this.expanded;
  }

  private expandableContainerHeight(container: HTMLDivElement): number {
    if (!this.expanded) {
      return 0;
    }
    let height = 0;
    for (let i = 0 ; i < container.children.length ; i++) {
      height += container.children.item(i)?.clientHeight ?? 0;
    }
    return height;
  }

}
