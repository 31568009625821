import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {RoutesEnum} from '../routes.enum';

import {CeremonyComponent} from './ceremony.component';
import {SongPageComponent} from './components/song-page/song-page.component';

const routes: Routes = [
  {
    path: RoutesEnum.CEREMONY,
    component: CeremonyComponent,
  },
  {
    path: RoutesEnum.SONG,
    children: [
      {path: '**', component: SongPageComponent},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class CeremonyRoutingModule {
}
