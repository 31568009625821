import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AccessEnum} from '../services/state/access-enum';
import {StateService} from '../services/state/state.service';

@Directive({
  selector: '[ifwAccessGranted]',
})
export class AccessGrantedDirective {

  private static readonly ACCESS_ORDER = [
    AccessEnum.NONE,
    AccessEnum.STANDARD,
    AccessEnum.FULL,
  ];

  constructor(
    private readonly _element: ElementRef,
    private readonly _templateRef: TemplateRef<any>,
    private readonly _viewContainer: ViewContainerRef,
    private readonly _stateService: StateService,
  ) {
  }

  @Input()
  public set ifwAccessGranted(value: AccessEnum) {
    if (AccessGrantedDirective.ACCESS_ORDER.indexOf(value) <= AccessGrantedDirective.ACCESS_ORDER.indexOf(this._stateService.access)) {
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this._viewContainer.clear();
    }
  }

}
