import {Component, ElementRef, OnInit} from '@angular/core';

@Component({
  selector: 'ifw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private readonly element: ElementRef<HTMLElement>,
  ) {
  }

  public ngOnInit(): void {
  }

  public onActivate(): void {
    // Prevent the top part to be hidden by the navigator UI on some platforms
    window.scrollTo({
      top: -this.element.nativeElement.clientHeight,
      behavior: 'smooth',
    });
  }
}
