import {Component} from '@angular/core';

@Component({
  selector: 'ifw-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {

}
