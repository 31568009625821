import { Component } from '@angular/core';

@Component({
  selector: 'a[ifw-link]',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent {

}
