import {Component, Input} from '@angular/core';
import {AccessEnum} from '../../services/state/access-enum';
import {RoutesEnum} from 'src/app/pages/routes.enum';

@Component({
  selector: 'ifw-top-bar-layout',
  templateUrl: './top-bar-layout.component.html',
  styleUrls: ['./top-bar-layout.component.scss'],
})
export class TopBarLayoutComponent {

  public readonly AccessEnum = AccessEnum;
  public readonly RoutesEnum = RoutesEnum;

  @Input()
  homeSelected = false;

  @Input()
  ceremonySelected = false;

  @Input()
  receptionSelected = false;

  @Input()
  listSelected = false;

  @Input()
  photosSelected = false;

}
