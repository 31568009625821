import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'ifw-song-card',
  templateUrl: './song-card.component.html',
  styleUrls: [
    '../song.common.scss',
    './song-card.component.scss',
  ],
})
export class SongCardComponent {

  @Input()
  public title: string = '';

  @Input()
  public subtitle: string = '';

  @Input()
  public route: string = '';

  constructor(
    private readonly _router: Router,
  ) {
  }

  public onClick(): void {
    this._router.navigate([`/song/${this.route}`]);
  }
}
