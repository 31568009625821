import {Injectable} from '@angular/core';
import {Song} from './song';
import {MusicBridge} from './music-bridge';

@Injectable()
export class SongService {

  public readonly songs: Array<Song> = [
    new Song(
      'Procession',
      'Céleste Jérusalem',
      'procession',
      'Notre cité se trouve dans les cieux,\n' +
      'Nous verrons l’Epouse de l’Agneau,\n' +
      'Resplendissante de la gloire de Dieu,\n' +
      'Céleste Jérusalem!',
      [
        'L’Agneau deviendra notre flambeau,\n' +
        'Nous nous passerons du soleil,\n' +
        'Il n’y aura plus jamais de nuit,\n' +
        'Dieu répandra sur nous sa lumière.',
        'Dieu aura sa demeure avec nous,\n' +
        'Il essuiera les larmes de nos yeux,\n' +
        'Il n’y aura plus de pleurs ni de peines\n' +
        'Car l’ancien monde s’en est allé.',
        'Et maintenant, voici le salut,\n' +
        'Le règne et la puissance de Dieu,\n' +
        'Soyez donc dans la joie vous les Cieux,\n' +
        'Il règnera sans fin dans les siècles.',
      ],
      '',
      null,
      false,
    ),
    new Song(
      'Entrée',
      'Levons les yeux',
      'entry',
      'Levons les yeux, voici la vraie lumière,\n' +
      'Voici le Christ qui nous donne la paix !\n' +
      'Ouvrons nos cœurs à sa miséricorde,\n' +
      'Notre Sauveur est au milieu de nous !',
      [
        'Jésus Christ, le Fils de Dieu fait homme\n' +
        'Vient demeurer au milieu de son peuple !\n' +
        'Regardez ! Voici l’Emmanuel !\n' +
        'Dieu avec nous, venu dans notre chair !',
        'Il est Dieu, il est notre lumière,\n' +
        'Rayon jailli du cœur très saint du Père.\n' +
        'Sa clarté embrase l’univers,\n' +
        'Il est la vie illuminant la nuit !',
        'C’est par lui que fut créé le monde\n' +
        'Pour l’habiter, l’habiller de sa gloire.\n' +
        'Par son nom Dieu se révèle à nous.\n' +
        'Accueillons-le, Il vient parmi les siens !',
        'Viens, Jésus ! Entre dans ton saint temple !\n' +
        'Nourris nos cœurs, donne-nous ta Parole !\n' +
        'Nous voici, tes enfants rassemblés :\n' +
        'Parle, Seigneur, et nous écouterons !',
        'Entendons l’appel de la sagesse,\n' +
        'L’Époux très saint nous invite à ses noces.\n' +
        '« Venez tous au banquet de l’Agneau,\n' +
        'Mangez ce pain et buvez de ce vin » !',
      ],
      '',
      null,
      false,
    ),
    new Song(
      'Gloria',
      'Messe de St Augustin',
      'gloria',
      'Gloria, gloria in excelsis Deo !\n' +
      'Gloria, gloria in excelsis Deo !',
      [
        'Et paix sur la terre aux hommes qu’il aime.\n' +
        'Nous te louons, nous te bénissons,\n' +
        'Nous t’adorons,\n' +
        'Nous te glorifions, nous te rendons grâce\n' +
        'Pour ton immense gloire,\n' +
        'Seigneur Dieu, Roi du Ciel,\n' +
        'Dieu le Père tout-puissant.\n' +
        'Seigneur, Fils unique, Jésus Christ,\n' +
        'Seigneur Dieu, Agneau de Dieu\n' +
        'Le Fils du Père,\n' +
        'Toi qui enlèves les péchés du monde,\n' +
        'Prends pitié de nous ;\n' +
        'Toi qui enlèves les péchés du monde,\n' +
        'Reçois notre prière,\n' +
        'Toi qui es assis à la droite du Père,\n' +
        'Prends pitié de nous.\n' +
        'Car toi seul es Saint,\n' +
        'Toi seul es Seigneur,\n' +
        'Toi seul es le Très-Haut, Jésus Christ,\n' +
        'Avec le Saint Esprit,\n' +
        'Dans la gloire de Dieu le Père.\n' +
        'Amen.',
      ],
      '',
      null,
      false,
    ),
    new Song(
      'Psaume',
      'Le Seigneur est ma Lumière (n° 27)',
      'psalm',
      'Le Seigneur est ma lumière et mon salut ; \n' +
      'De qui aurais-je crainte ?\n' +
      'Le Seigneur est le rempart de ma vie ;\n' +
      'Devant qui tremblerais-je ?',
      [
        'J’ai demandé une chose au Seigneur, La seule que je cherche :\n' +
        'Habiter la maison du Seigneur Tous les jours de ma vie.',
        'Habiter la maison du Seigneur, Pour t’admirer en ta bonté\n' +
        'Et m’attacher à ton Eglise Seigneur, M’attacher à ton Eglise, Seigneur.',
        'J’en suis sûr, je verrai les bontés du Seigneur Sur la terre des vivants.\n' +
        '“Espère, sois fort et prends courage ; Espère, espère le Seigneur.”',
      ],
      '',
      null,
      true,
    ),
    new Song(
      'Louange',
      'À Lui la gloire',
      'praise',
      'À lui, la gloire et la louange\n' +
      'Pour l’éternité.\n' +
      'À lui, la gloire et la louange\n' +
      'Pour l’éternité.',
      [
        'Vous, les œuvres du Seigneur, Bénissez le Seigneur !\n' +
        'Vous, les anges et vous les cieux, Bénissez le Seigneur !\n' +
        'Les eaux par-dessus le ciel, Bénissez le Seigneur !\n' +
        'Vous, les puissances d’en haut, Bénissez le Seigneur !',
        'Vous, le soleil et la lune, Bénissez le Seigneur !\n' +
        'Et vous, les astres du ciel, Bénissez le Seigneur !\n' +
        'Vous, les pluies et les rosées, Bénissez le Seigneur !\n' +
        'Et vous, les souffles et vents, Bénissez le Seigneur !',
        'Vous, le feu et la chaleur, Bénissez le Seigneur !\n' +
        'Vous, la fraîcheur et le froid, Bénissez le Seigneur !\n' +
        'Vous, la pluie et la rosée, Bénissez le Seigneur !\n' +
        'Vous, les glaces et les neiges, Bénissez le Seigneur !',
        'Et vous, les nuits et les jours, Bénissez le Seigneur !\n' +
        'La lumière et les ténèbres, Bénissez le Seigneur !\n' +
        'Et vous, éclairs et nuées, Bénissez le Seigneur !\n' +
        'Que la terre entière acclame, Bénissez le Seigneur !',
        'Vous, montagnes et collines, Bénissez le Seigneur !\n' +
        'Et vous, plantes de la terre, Bénissez le Seigneur !\n' +
        'Vous, les sources et fontaines, Bénissez le Seigneur !\n' +
        'Les océans, les rivières, Bénissez le Seigneur !',
        'Vous, les bêtes de la mer, Bénissez le Seigneur !\n' +
        'Vous, les oiseaux dans le ciel, Bénissez le Seigneur !\n' +
        'Vous, les fauves et troupeaux, Bénissez le Seigneur !\n' +
        'Créatures de la terre, Bénissez le Seigneur !',
        'Et vous, les enfants des hommes, Bénissez le Seigneur !\n' +
        'Vous, les enfants d’Israël, Bénissez le Seigneur !\n' +
        'Vous, ses prêtres et serviteurs, Bénissez le Seigneur !\n' +
        'Les saints et humbles de cœur, Bénissez le Seigneur !',
        'Rendons gloire à notre Dieu, Bénissons le Seigneur !\n' +
        'Père, Fils et Saint Esprit, Bénissons le Seigneur !\n' +
        'Maintenant et à jamais, Bénissons le Seigneur !\n' +
        'Dans tous les siècles des siècles, Bénissons le Seigneur !',
      ],
      '',
      null,
      false,
    ),
    new Song(
      'Offertoire',
      'Ne crains pas',
      'offertory',
      'Ne crains pas, je suis ton Dieu,\n' +
      'C’est moi qui t’ai choisi, appelé par ton nom.\n' +
      'Tu as du prix à mes yeux et je t’aime.\n' +
      'Ne crains pas car je suis avec toi.',
      [
        'Toi, mon serviteur, je te soutiendrai,\n' +
        'Toi mon élu que préfère mon âme\n' +
        'Je mettrai en toi mon Esprit\n' +
        'Je te comblerai de mon Esprit.',
        'Le Seigneur m’a appelé dès le sein de ma mère,\n' +
        'Il a prononcé mon nom.\n' +
        'C’est lui qui m’a formé pour être son serviteur,\n' +
        'Le témoin de sa gloire.',
      ],
      '',
      null,
      false,
    ),
    new Song(
      'Sanctus',
      'Messe de St Augustin',
      'sanctus',
      'Sanctus, Sanctus, Sanctus\n' +
      'Dominus Deus Sabaoth !\n' +
      'Sanctus, Sanctus, Sanctus\n' +
      'Dominus Deus Sabaoth !',
      [
        'Pleni sunt caeli et terra gloria tua.\n' +
        'Hosanna ! Hosanna !\n' +
        'Hosanna, in excelsis !\n' +
        'Hosanna ! Hosanna !\n' +
        'Hosanna, in excelsis !\n' +
        'Benedictus qui venit in nomini Domini !\n' +
        'Hosanna ! Hosanna !\n' +
        'Hosanna, in excelsis !\n' +
        'Hosanna ! Hosanna !\n' +
        'Hosanna, in excelsis !',
      ],
      '',
      null,
      false,
    ),
    new Song(
      'Notre Père',
      'Glorious harmonisé par Pierre Gutekunst',
      'pater-noster',
      '',
      [
        'Notre Père, qui es aux cieux,\n' +
        'Que Ton nom soit sanctifié,\n' +
        'Que Ton règne vienne,\n' +
        'Que Ta volonté soit faite sur la terre comme au ciel.\n' +
        'Donne-nous aujourd\'hui notre pain de ce jour,\n' +
        'Pardonne-nous nos offenses,\n' +
        'Comme nous pardonnons aussi à ceux qui nous ont offensés.\n' +
        'Ne nous laisse pas entrer en tentation,\n' +
        'Mais délivre-nous du mal.\n' +
        'Car c\'est à Toi qu\'appartiennent le règne, la puissance et la gloire,\n' +
        'Pour les siècles des siècles,\n' +
        'Amen.',
      ],
      '',
      null,
      false,
    ),
    new Song(
      'Agnus',
      'Messe de St Augustin',
      'agnus',
      '',
      [
        'Agnus Dei,\n' +
        'Qui tollis peccata mundi,\n' +
        'Miserere nobis.',
        'Agnus Dei,\n' +
        'Qui tollis peccata mundi,\n' +
        'Miserere nobis.',
        'Agnus Dei,\n' +
        'Qui tollis peccata mundi,\n' +
        'Dona nobis pacem !',
      ],
      '',
      null,
      false,
    ),
    new Song(
      'Communion',
      'Regardez l\'humilité de Dieu',
      'communion',
      'Regardez l’humilité de Dieu, Regardez l’humilité de Dieu,\n' +
      'Regardez l’humilité de Dieu, et faites-lui hommage de vos cœurs.',
      [
        'Admirable grandeur, étonnante bonté du Maître de l’univers\n' +
        'Qui s’humilie pour nous au point de se cacher dans une petite hostie de pain.',
        'Faites-vous tout petits, vous aussi devant Dieu pour être élevés par Lui,\n' +
        'Ne gardez rien pour vous, offrez-vous tout entiers à ce Dieu qui se donne à vous.',
      ],
      '',
      null,
      false,
    ),
    new Song(
      'Action de grâce',
      'Chant de la promesse',
      'thanksgiving',
      'Je veux t\'aimer sans cesse,\n' +
      'De plus en plus.\n' +
      'Protège ma promesse,\n' +
      'Seigneur Jésus.',
      [
        'Devant tous, je m\'engage,\n' +
        'Sur mon honneur,\n' +
        'Et je te fais hommage\n' +
        'De moi, Seigneur.',
        'Je jure de te suivre,\n' +
        'En fier chrétien,\n' +
        'Et, tout entier, je livre\n' +
        'Mon cœur au tien.',
        'Je suis de tes apôtres\n' +
        'Et, chaque jour,\n' +
        'Je veux aider les autres,\n' +
        'Pour ton amour.',
        'Ta règle a sur nous-mêmes\n' +
        'Un droit sacré.\n' +
        'Je suis faible, tu m\'aimes ;\n' +
        'Je maintiendrai.',
        'Fidèle à ma patrie,\n' +
        'Je le serai\n' +
        'Tous les jours de ma vie,\n' +
        'Je servirai.',
      ],
      '',
      null,
      false,
    ),
    new Song(
      'À Marie',
      'Regarde l\'étoile',
      'maria',
      'Regarde l’étoile, invoque Marie,\n' +
      'Si tu la suis, tu ne crains rien !\n' +
      'Regarde l’étoile, invoque Marie,\n' +
      'Elle te conduit sur le chemin !',
      [
        'Si le vent des tentations s’élève,\n' +
        'Si tu heurtes le rocher des épreuves.\n' +
        'Si les flots de l’ambition t’entraînent,\n' +
        'Si l’orage des passions se déchaîne :',
        'Dans l’angoisse et les périls, le doute,\n' +
        'Quand la nuit du désespoir te recouvre.\n' +
        'Si devant la gravité de tes fautes\n' +
        'La pensée du jugement te tourmente :',
        'Si ton âme est envahie de colère,\n' +
        'Jalousie et trahison te submergent.\n' +
        'Si ton coeur est englouti dans le gouffre,\n' +
        'Emporté par les courants de tristesse :',
        'Elle se lève sur la mer, elle éclaire,\n' +
        'Son éclat et ses rayons illuminent.\n' +
        'Sa lumière resplendit sur la terre,\n' +
        'Dans les cieux et jusqu’au fond des abîmes.',
      ],
      'Si tu la suis, tu ne dévies pas,\n' +
      'Si tu la pries, tu ne faiblis pas.\n' +
      'Tu ne crains rien, elle est avec toi,\n' +
      'Et jusqu’au port, elle te guidera.',
      null,
      false,
    ),
    new Song(
      'Sortie',
      'Que vienne Ton règne',
      'end',
      'Que vienne ton règne,\n' +
      'Que ton Nom soit sanctifié,\n' +
      'Sur la terre comme au ciel,\n' +
      'Que ta volonté soit faite.\n' +
      'Que coule en torrents\n' +
      'Ton Esprit de vérité.\n' +
      'Donne-nous ton espérance,\n' +
      'Ton amour, ta sainteté.',
      [
        'Qui pourrait nous séparer\n' +
        'De ton amour immense ?\n' +
        'Qui pourrait nous détourner\n' +
        'De ta miséricorde ?',
        'Tu habites nos louanges,\n' +
        'Tu inspires nos prières,\n' +
        'Nous attires en ta présence\n' +
        'Pour nous tourner vers nos frères.',
        'Tu seras notre lumière,\n' +
        'Il n’y aura plus de nuit.\n' +
        'Ton Nom sera sur nos lèvres,\n' +
        'De larmes, il n’y aura plus.',
      ],
      '',
      new MusicBridge(
        'Délivre-nous de tout mal,\n' +
        'Donne la paix à ce temps !\n' +
        'Libère-nous du péché,\n' +
        'Toi qui fais miséricorde !\n' +
        'Rassure-nous dans l’épreuve,\n' +
        'Nous espérons ton Royaume !\n' +
        'Tu nous promets le bonheur,\n' +
        'L’Avènement de Jésus !',
        2,
      ),
      false,
    ),
  ];

  constructor() {
  }
}
