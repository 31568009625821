<ifw-horizontal-divider [configuration]="['dashed']"></ifw-horizontal-divider>

<div class="headerWrapper">
  <div class="header" (click)="expandCollapse()">
    <ifw-icon iconName="chevron"></ifw-icon>
    <ng-content select="[ifw-expansion-panel-header]"></ng-content>
  </div>
  <div #subheader class="subheader" [ngStyle]="{'height.px': subheaderHeight}">
    <ng-content select="[ifw-expansion-panel-subheader]"></ng-content>
  </div>
</div>

<ifw-horizontal-divider [configuration]="['dashed']"></ifw-horizontal-divider>

<div #body class="body" [ngStyle]="{'height.px': bodyHeight}">
  <ng-content></ng-content>
</div>

<ifw-horizontal-divider [configuration]="['dashed']"></ifw-horizontal-divider>
