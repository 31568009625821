import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'ifw-song-verse',
  templateUrl: './song-verse.component.html',
  styleUrls: ['./song-verse.component.scss'],
})
export class SongVerseComponent {

  @Input()
  public number: number = 0;

  @Input()
  public text: string = '';

  @Input()
  @HostBinding('class.chorus')
  public chorus: boolean = false;

  @Input()
  @HostBinding('class.coda')
  public coda: boolean = false;

  @Input()
  @HostBinding('class.bridge')
  public bridge: boolean = false;

  @Input()
  public displayNumber: boolean = true;

  @HostBinding('class.numberless')
  public get numberless(): boolean {
    return !this.displayNumber;
  }

  public get numberString(): string {
    if (this.chorus) {
      return 'R';
    }
    if (this.coda) {
      return 'C';
    }
    if (this.bridge) {
      return 'Pont';
    }
    return this.number.toFixed(0);
  }

  public get textHtml(): string {
    return this.text.replaceAll('\n', '<br/>');
  }

}
