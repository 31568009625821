import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReceptionComponent} from './reception.component';
import {CoreModule} from '../../core/core.module';
import {SharedModule} from '../../shared/shared.module';
import {AccommodationComponent} from './components/accommodation/accommodation.component';
import {AccommodationService} from './services/accommodation/accommodation.service';


@NgModule({
  declarations: [
    ReceptionComponent,
    AccommodationComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
  ],
  providers: [
    AccommodationService,
  ],
})
export class ReceptionModule {
}
