import {Component} from '@angular/core';

@Component({
  selector: 'ifw-work-in-progress',
  templateUrl: './work-in-progress.component.html',
  styleUrls: ['./work-in-progress.component.scss'],
})
export class WorkInProgressComponent {

}
