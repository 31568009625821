import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {RoutesEnum} from '../routes.enum';

import {ReceptionComponent} from './reception.component';

const routes: Routes = [
  {
    path: RoutesEnum.RECEPTION,
    component: ReceptionComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class ReceptionRoutingModule {
}
