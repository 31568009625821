import {Component} from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'ifw-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss'],
})
export class PhotosComponent {

  constructor() {
  }

  public get downloadCode(): number {
    return 9439;
  }

}
