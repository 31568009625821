import {Component, Input} from '@angular/core';

@Component({
  selector: 'ifw-practical-information',
  templateUrl: './practical-information.component.html',
  styleUrls: ['./practical-information.component.scss']
})
export class PracticalInformationComponent {

  @Input()
  public subtitle: string = '';

}
