import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {AccessInterface} from './access.interface';
import {environment} from '../../../../environments/environment';
import {RsvpInterface} from './rsvp.interface';
import {DriveTypeEnum} from './drive/drive-type.enum';
import {DriveListInterface} from './drive/drive-list.interface';
import {DriveInterface} from './drive/drive.interface';
import {PassengerInterface} from './drive/passenger.interface';

@Injectable()
export class ApiService {

  constructor(
    private readonly _http: HttpClient) {
  }

  public getAccess(password: string): Observable<HttpResponse<AccessInterface>> {
    return this._http.get<AccessInterface>(environment.apiUrl + '/api/login/' + password, {
      observe: 'response',
      responseType: 'json',
      reportProgress: true,
    });
  }

  public getDriveList(type: DriveTypeEnum): Observable<HttpResponse<DriveListInterface>> {
    return this._http.get<DriveListInterface>(environment.apiUrl + '/api/list_drives', {
      observe: 'response',
      responseType: 'json',
      params: {
        type: type,
      },
      reportProgress: true,
    });
  }

  public postAddDrive(drive: DriveInterface): Observable<HttpResponse<DriveListInterface>> {
    return this._http.post<DriveListInterface>(environment.apiUrl + '/api/add_drive', null, {
      observe: 'response',
      responseType: 'json',
      params: {
        // conductor related
        firstName: drive.conductor.firstName,
        lastName: drive.conductor.lastName,
        email: drive.conductor.email,
        telephone: drive.conductor.telephone,
        notes: drive.conductor.notes,
        // drive related
        fromLocation: drive.fromLocation,
        toLocation: drive.toLocation,
        availablePlaces: drive.availablePlaces,
        type: drive.type,
        driveNotes: drive.notes,
      },
      reportProgress: true,
    });
  }

  public postAddPassenger(passenger: PassengerInterface, drive: DriveInterface): Observable<HttpResponse<DriveListInterface>> {
    return this._http.post<DriveListInterface>(environment.apiUrl + '/api/add_passenger', null, {
      observe: 'response',
      responseType: 'json',
      params: {
        // passenger related
        firstName: passenger.firstName,
        lastName: passenger.lastName,
        email: passenger.email,
        telephone: passenger.telephone,
        notes: passenger.notes,
        // drive related
        driveId: drive.id,
      },
      reportProgress: true,
    });
  }

  public postRsvp(rsvp: RsvpInterface): Observable<boolean> {
    return this._http.post<any>(environment.apiUrl + '/api/rsvp', null, {
      observe: 'response',
      params: rsvp,
    }).pipe(
      map(response => response.ok),
    );
  }
}
