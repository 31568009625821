import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CeremonyComponent} from './ceremony.component';
import {CoreModule} from '../../core/core.module';
import {SharedModule} from '../../shared/shared.module';
import {SongCardComponent} from './components/song-card/song-card.component';
import {SongService} from './services/song/song.service';
import { SongPageComponent } from './components/song-page/song-page.component';
import {RouterOutlet} from '@angular/router';
import { SongVerseComponent } from './components/song-verse/song-verse.component';


@NgModule({
  declarations: [
    CeremonyComponent,
    SongCardComponent,
    SongPageComponent,
    SongVerseComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    RouterOutlet,
  ],
  providers: [
    SongService,
  ],
})
export class CeremonyModule {
}
