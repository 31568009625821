import {APP_INITIALIZER, FactoryProvider} from '@angular/core';

export interface OnAppInit<T = void> {
  onAppInit(): T;
}

export function serviceInitializer<T extends OnAppInit>(service: new (...params: any[]) => T): FactoryProvider {
  return {
    provide: APP_INITIALIZER,
    deps: [service],
    useFactory: (service: T) => (() => service.onAppInit()),
    multi: true,
  };
}
