import {Component} from '@angular/core';

@Component({
  selector: 'button[ifw-burger-menu-button]',
  templateUrl: './burger-menu-button.component.html',
  styleUrls: ['./burger-menu-button.component.scss'],
})
export class BurgerMenuButtonComponent {

}
