<ifw-top-bar-layout [ceremonySelected]="true">
  <div class="contentWrapper">
    <ifw-section [title]="'CEREMONY.PRACTICAL_INFORMATION.TITLE' | translate">
      <div class="infoSectionContent">
        <ifw-practical-information class="info"
                                   [subtitle]="'CEREMONY.PRACTICAL_INFORMATION.SUBTITLE' | translate">
          <ifw-practical-information-section [title]="'CEREMONY.PRACTICAL_INFORMATION.ADDRESS.TITLE' | translate"
                                             [text]="'CEREMONY.PRACTICAL_INFORMATION.ADDRESS.TEXT' | translate">
          </ifw-practical-information-section>
          <ifw-practical-information-section [title]="'CEREMONY.PRACTICAL_INFORMATION.TRAMWAY.TITLE' | translate"
                                             [text]="'CEREMONY.PRACTICAL_INFORMATION.TRAMWAY.TEXT' | translate">
          </ifw-practical-information-section>
        </ifw-practical-information>

        <ifw-google-map class="map"
                        [parameters]="'CEREMONY.PRACTICAL_INFORMATION.MAP_PARAMETERS' | translate">
        </ifw-google-map>
      </div>
    </ifw-section>

    <ifw-horizontal-divider [configuration]="['thick','thin']"></ifw-horizontal-divider>

    <ifw-section [title]="'CEREMONY.SONGS.TITLE' | translate">
      <p class="temporaryMessage"
         [innerHTML]="'CEREMONY.SONGS.LOOKING_FOR_TALENT' | translate">
      </p>
      <div class="songsWrapper">
        <ifw-song-card *ngFor="let song of songs"
                       [title]="song.title"
                       [subtitle]="song.subtitle"
                       [route]="song.route">
        </ifw-song-card>
      </div>
    </ifw-section>
  </div>
</ifw-top-bar-layout>
