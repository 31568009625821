import {Injectable} from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';
import {filter, firstValueFrom, map, tap, catchError, of} from 'rxjs';
import {AccessEnum} from '../../../../core/services/state/access-enum';
import {StateService} from '../../../../core/services/state/state.service';
import {OnAppInit} from '../../../../shared/services/service-initializer';
import {HttpResponse} from '@angular/common/http';
import {AccessInterface} from '../../../../core/services/api/access.interface';
import {ApiService} from '../../../../core/services/api/api.service';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class LoginService implements OnAppInit {

  private static STORED_ACCESS_KEY = 'access';
  private static STORED_ROUTE_KEY = 'route';

  private _requestProcessing = false;
  private _serverError = false;

  constructor(
    private readonly _stateService: StateService,
    private readonly _router: Router,
    private readonly _apiService: ApiService,
  ) {
  }

  public get requestProcessing(): boolean {
    return this._requestProcessing;
  }

  public get serverError(): boolean {
    return this._serverError;
  }

  private get redirectTo(): string {
    return this.lastRoute ?? '/home';
  }

  private get storedAccess(): AccessEnum | null {
    return sessionStorage.getItem(LoginService.STORED_ACCESS_KEY) as AccessEnum | null;
  }

  private set storedAccess(value: AccessEnum | null) {
    if (value === null) {
      sessionStorage.removeItem(LoginService.STORED_ACCESS_KEY);
    } else {
      sessionStorage.setItem(LoginService.STORED_ACCESS_KEY, value);
    }
  }

  private get lastRoute(): string | null {
    return sessionStorage.getItem(LoginService.STORED_ROUTE_KEY);
  }

  private set lastRoute(value: string | null) {
    if (value === null) {
      sessionStorage.removeItem(LoginService.STORED_ROUTE_KEY);
    } else {
      sessionStorage.setItem(LoginService.STORED_ROUTE_KEY, value);
    }
  }

  public onAppInit(): void {
    if (this.storedAccess !== null) {
      console.info('Access', this.storedAccess, 'stored, redirecting to', this.redirectTo);
      this._stateService.access = this.storedAccess;
      this._router.navigate([this.redirectTo]);
    } else {
      const url = new URL(document.URL);
      const passwordParameter = url.searchParams.get('password');
      if (passwordParameter !== null) {
        console.info('Logging in from URL parameter');
        this.login(passwordParameter);
      }
    }

    this._router.events.pipe(
      filter((): boolean => this._stateService.access !== AccessEnum.NONE),
      filter((event: Event): boolean => event instanceof NavigationEnd),
      map((event: Event): NavigationEnd => event as NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      this.lastRoute = event.urlAfterRedirects;
      console.info('Navigation to route', this.lastRoute, 'stored');
      Sentry.captureMessage(`Navigated to ${this.lastRoute}`, 'info');
    });
  }

  /**
   * Sends an asynchronous request to log in.
   * @param password Password value
   */
  public async login(password: string): Promise<boolean> {
    if (this._requestProcessing) {
      console.warn('Unable to login, previous request is still processing');
      return Promise.resolve(false);
    }

    this._requestProcessing = true;
    this._serverError = false;
    console.info('Logging in with password', password);
    return firstValueFrom(this._apiService.getAccess(password).pipe(
      map((response: HttpResponse<AccessInterface>): boolean => {
        if (response.ok && (response.body?.access === AccessEnum.FULL || response.body?.access === AccessEnum.STANDARD)) {
          this._stateService.access = response.body?.access;
        } else {
          this._stateService.access = AccessEnum.NONE;
          console.info('Access denied');
          Sentry.captureMessage('Access denied');
          return false;
        }

        console.info('Access granted:', AccessEnum[this._stateService.access]);
        this.storedAccess = this._stateService.access;
        if (this.lastRoute === '/login') {
          this.lastRoute = null;
        }
        console.info('Navigating to route', this.redirectTo);
        this._router.navigate([this.redirectTo]);
        Sentry.captureMessage(`Access granted: ${AccessEnum[this._stateService.access]}`);
        return true;
      }),
      catchError((error) => {
        console.error('Error caught in login request:', error);
        Sentry.captureException(error);
        this._serverError = true;
        return of(false);
      }),
      tap(() => this._requestProcessing = false),
    ));
  }
}
