import {Component, Input} from '@angular/core';
import {AccommodationInterface} from '../../services/accommodation/accommodation.interface';

@Component({
  selector: 'ifw-accommodation',
  templateUrl: './accommodation.component.html',
  styleUrls: ['./accommodation.component.scss'],
})
export class AccommodationComponent {

  @Input()
  public accommodation?: AccommodationInterface;

  public format(text?: string | undefined): string {
    return text?.replaceAll('\n', '<br/>') ?? '';
  }
}
