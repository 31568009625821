import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './shared/components/page-not-found/page-not-found.component';

import {LoginRoutingModule} from './pages/login/login-routing.module';
import {HomeRoutingModule} from './pages/home/home-routing.module';
import {CeremonyRoutingModule} from './pages/ceremony/ceremony-routing.module';
import {ReceptionRoutingModule} from './pages/reception/reception-routing.module';
import {RoutesEnum} from './pages/routes.enum';
import {PhotosRoutingModule} from './pages/photos/photos-routing.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: RoutesEnum.LOGIN,
    pathMatch: 'full',
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    LoginRoutingModule,
    HomeRoutingModule,
    CeremonyRoutingModule,
    ReceptionRoutingModule,
    PhotosRoutingModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
