import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login.component';
import {CoreModule} from '../../core/core.module';
import {SharedModule} from '../../shared/shared.module';
import {LoginService} from './services/login/login.service';
import {serviceInitializer} from '../../shared/services/service-initializer';


@NgModule({
  declarations: [
    LoginComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
  ],
  providers: [
    LoginService,
    serviceInitializer(LoginService),
  ],
})
export class LoginModule {
}
