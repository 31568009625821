import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {RoutesEnum} from '../routes.enum';

import {HomeComponent} from './home.component';

const routes: Routes = [
  {
    path: RoutesEnum.HOME,
    component: HomeComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class HomeRoutingModule {
}
