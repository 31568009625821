import {Component, Input} from '@angular/core';

@Component({
  selector: 'ifw-horizontal-divider',
  templateUrl: './horizontal-divider.component.html',
  styleUrls: ['./horizontal-divider.component.scss'],
})
export class HorizontalDividerComponent {

  @Input()
  public configuration: Array<'dashed' | 'thin' | 'thick'> = ['thin'];

}
