import {MusicBridge} from './music-bridge';

export class Song {

  constructor(
    public readonly title: string,
    public readonly subtitle: string,
    public readonly route: string,
    public readonly chorus: string,
    public readonly verses: Array<string>,
    public readonly coda: string,
    public readonly bridge: MusicBridge | null,
    public readonly counterpoint: boolean,
  ) {
  }

}
