<ifw-top-bar-layout [receptionSelected]="true">
  <div class="contentWrapper">
    <ifw-section [title]="'RECEPTION.PRACTICAL_INFORMATION.TITLE' | translate">
      <div class="infoSectionContent">
        <ifw-practical-information class="info"
                                   [subtitle]="'RECEPTION.PRACTICAL_INFORMATION.SUBTITLE' | translate">
          <ifw-practical-information-section [title]="'RECEPTION.PRACTICAL_INFORMATION.ADDRESS.TITLE' | translate"
                                             [text]="'RECEPTION.PRACTICAL_INFORMATION.ADDRESS.TEXT' | translate">
          </ifw-practical-information-section>
          <a ifw-link
             class="directionsLink"
             href="https://www.google.com/maps/dir/%C3%89glise+Saint-Pierre+et+Saint-Paul,+Rue+du+Comt%C3%A9+de+Mons,+Jou%C3%A9-l%C3%A8s-Tours/Domaine+de+la+Fougeraie+D54+37370+Saint-Paterne-Racan/@47.4665466,0.3886821,11z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x47fcd619aa3014f9:0xa59a39b9a599da50!2m2!1d0.6613153!2d47.3518897!1m5!1m1!1s0x47e2cf3656efd583:0xeaa123d2c3292b96!2m2!1d0.418814!2d47.577882"
             target="_blank">
            {{ 'RECEPTION.PRACTICAL_INFORMATION.DIRECTIONS' | translate }}
            <ifw-icon iconName="external-link">
            </ifw-icon>
          </a>
          <ifw-practical-information-section [title]="'RECEPTION.PRACTICAL_INFORMATION.PARKING.TITLE' | translate"
                                             [text]="'RECEPTION.PRACTICAL_INFORMATION.PARKING.TEXT' | translate">
          </ifw-practical-information-section>
        </ifw-practical-information>

        <ifw-google-map class="map"
                        [parameters]="'RECEPTION.PRACTICAL_INFORMATION.MAP_PARAMETERS' | translate">
        </ifw-google-map>
      </div>
    </ifw-section>

    <ifw-horizontal-divider [configuration]="['thick','thin']"></ifw-horizontal-divider>

    <ifw-section [title]="'RECEPTION.ACCOMMODATION.TITLE' | translate">

      <div class="accommodationSubSection">
        <h3>{{ 'RECEPTION.ACCOMMODATION.HOTELS' | translate }}</h3>
        <div class="accommodationWrapper">
          <ifw-accommodation *ngFor="let accommodation of hotels" [accommodation]="accommodation"></ifw-accommodation>
        </div>
      </div>

      <ifw-horizontal-divider [configuration]="['thin']"></ifw-horizontal-divider>

      <div class="accommodationSubSection">
        <h3>{{ 'RECEPTION.ACCOMMODATION.BED_AND_BREAKFASTS' | translate }}</h3>
        <div class="accommodationWrapper">
          <ifw-accommodation *ngFor="let accommodation of bedAndBreakfasts"
                             [accommodation]="accommodation"></ifw-accommodation>
        </div>
      </div>

      <ifw-horizontal-divider [configuration]="['thin']"></ifw-horizontal-divider>

      <div class="accommodationSubSection">
        <h3>{{ 'RECEPTION.ACCOMMODATION.GITES' | translate }}</h3>
        <div class="accommodationWrapper">
          <ifw-accommodation *ngFor="let accommodation of gites" [accommodation]="accommodation"></ifw-accommodation>
        </div>
      </div>

    </ifw-section>
  </div>
</ifw-top-bar-layout>
