import {Component, Input} from '@angular/core';

@Component({
  selector: 'ifw-practical-information-section',
  templateUrl: './practical-information-section.component.html',
  styleUrls: ['./practical-information-section.component.scss']
})
export class PracticalInformationSectionComponent {

  @Input()
  public title: string = '';

  @Input()
  public text: string = '';

}
