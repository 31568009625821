import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PhotosComponent} from './photos.component';
import {CoreModule} from '../../core/core.module';
import {SharedModule} from '../../shared/shared.module';


@NgModule({
  declarations: [
    PhotosComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
  ],
})
export class PhotosModule {
}
