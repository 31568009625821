<ifw-top-bar-layout [ceremonySelected]="true">
  <h2 class="flourish">{{ song.title }}</h2>
  <h3 class="songTitle">{{ song.subtitle }}</h3>

  <div class="contentWrapper">
    <ifw-song-verse *ngIf="song.chorus.length > 0"
                    [text]="song.chorus"
                    [chorus]="true"
                    [displayNumber]="(song.bridge?.position ?? song.verses.length) > 1">
    </ifw-song-verse>

    <div class="versesWrapper"
         [class.singleVerse]="song.verses.length <= 1">
      <ng-container *ngFor="let verse of song.verses; let i = index">
        <ifw-song-verse *ngIf="song.bridge === null || i < song.bridge!.position"
                        [text]="verse"
                        [number]="i + 1"
                        [displayNumber]="song.verses.length > 1">
        </ifw-song-verse>
      </ng-container>
    </div>

    <ng-container *ngIf="song.bridge !== null">
      <ifw-song-verse [text]="song.bridge!.text"
                      [bridge]="true"
                      [displayNumber]="song.verses.length > 1">
      </ifw-song-verse>


      <div class="versesWrapper"
           [class.singleVerse]="(song.verses.length - song.bridge!.position) <= 1">
        <ng-container *ngFor="let verse of song.verses; let i = index">
          <ifw-song-verse *ngIf="i >= song.bridge!.position"
                          [text]="verse"
                          [number]="i + 1"
                          [displayNumber]="song.verses.length > 1">
          </ifw-song-verse>
        </ng-container>
      </div>
    </ng-container>

    <ifw-song-verse *ngIf="song.coda.length > 0"
                    [text]="song.coda"
                    [coda]="true"
                    [displayNumber]="song.verses.length > 1">
    </ifw-song-verse>

    <a ifw-link
       [href]="assetsFolder + '/sheet-music.pdf'"
       target="_blank">
      {{ 'SONG.SHEET_MUSIC' | translate }}
      <ifw-icon iconName="external-link">
      </ifw-icon>
    </a>

    <div class="audioSection">
      <ifw-audio [src]="assetsFolder + '/tutti.mp3'"
                 [label]="'SONG.AUDIO.TUTTI' | translate">
      </ifw-audio>
    </div>

    <div class="audioSection">
      <ifw-audio [src]="assetsFolder + '/soprano.mp3'"
                 [label]="'SONG.AUDIO.SOPRANO' | translate">
      </ifw-audio>
      <ifw-audio [src]="assetsFolder + '/tenor.mp3'"
                 [label]="'SONG.AUDIO.TENOR' | translate">
      </ifw-audio>
    </div>

    <div class="audioSection">
      <ifw-audio [src]="assetsFolder + '/alto.mp3'"
                 [label]="'SONG.AUDIO.ALTO' | translate">
      </ifw-audio>
      <ifw-audio [src]="assetsFolder + '/bass.mp3'"
                 [label]="'SONG.AUDIO.BASS' | translate">
      </ifw-audio>
    </div>

    <div class="audioSection">
      <ifw-audio [class.hidden]="!displayCounterpoint"
                 [src]="assetsFolder + '/counterpoint.mp3'"
                 [label]="'SONG.AUDIO.COUNTERPOINT' | translate"
                 (disabledChange)="displayCounterpoint = !$event">
      </ifw-audio>
    </div>

  </div>

</ifw-top-bar-layout>
