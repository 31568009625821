import {Component, Input} from '@angular/core';

@Component({
  selector: 'ifw-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {

  @Input()
  public iconName: string = '';

  public get iconStyle() {
    const maskImage = `url(${this.iconSrc})`;
    const maskPosition = 'center';
    const maskRepeat = 'no-repeat';
    const maskMode = 'alpha';
    const maskSize = '100% 100%';
    return {
      'mask-image': maskImage,
      '-webkit-mask-image': maskImage,
      'mask-position': maskPosition,
      '-webkit-mask-position': maskPosition,
      'mask-repeat': maskRepeat,
      '-webkit-mask-repeat': maskRepeat,
      'mask-mode': maskMode,
      '-webkit-mask-mode': maskMode,
      'mask-size': maskSize,
      '-webkit-mask-size': maskSize,
    };
  }

  private get iconSrc(): string {
    return `assets/icons/${this.iconName}.svg`;
  }
}
