import {Component} from '@angular/core';

@Component({
  selector: 'button[ifw-button]',
  templateUrl: './button.component.html',
  styleUrls: [
    '../buttons.common.scss',
    './button.component.scss',
  ],
})
export class ButtonComponent {

}
