<p ifw-top-bar-tab class="selectedTab">{{ selectedTabContent }}</p>
<button ifw-burger-menu-button
        class="burgerMenuToggle"
        (click)="toggleLinksList()">
</button>

<div #linksList
     class="linksList"
     [ngStyle]="{top: topBarHeight + 'px', right: linksListDisplayed ? '0' : 'calc(-100%)'}">
  <ng-content></ng-content>
</div>
