<ifw-top-bar class="topBar">
  <a ifw-top-bar-tab
     [ifSelected]="homeSelected"
     [routerLink]="'/' + RoutesEnum.HOME">
    {{ 'TOP_BAR.HOME' | translate }}
  </a>
  <a ifw-top-bar-tab
     *ifwAccessGranted="AccessEnum.STANDARD"
     [ifSelected]="ceremonySelected"
     [routerLink]="'/' + RoutesEnum.CEREMONY">
    {{ 'TOP_BAR.CEREMONY' | translate }}
  </a>
  <a ifw-top-bar-tab
     *ifwAccessGranted="AccessEnum.FULL"
     [ifSelected]="receptionSelected"
     [routerLink]="'/' + RoutesEnum.RECEPTION">
    {{ 'TOP_BAR.RECEPTION' | translate }}
  </a>
  <a ifw-top-bar-tab
     *ifwAccessGranted="AccessEnum.STANDARD"
     [ifSelected]="listSelected"
     href="https://www.millemercismariage.com/ivan-florence/liste.html"
     target="_blank">
    {{ 'TOP_BAR.LIST' | translate }}
    <ifw-icon iconName="external-link">
    </ifw-icon>
  </a>
  <a ifw-top-bar-tab
     *ifwAccessGranted="AccessEnum.FULL"
     [ifSelected]="photosSelected"
     [routerLink]="'/' + RoutesEnum.PHOTOS">
    {{ 'TOP_BAR.PHOTOS' | translate }}
  </a>
</ifw-top-bar>

<div class="content">
  <ng-content></ng-content>
</div>
