import {AfterViewChecked, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'ifw-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit, AfterViewChecked {

  private _topBarHeight = 0;
  private _linksListDisplayed = false;

  @ViewChild('linksList', {static: true})
  private _linksList!: ElementRef<HTMLDivElement>;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
  ) {
  }

  public get topBarHeight(): number {
    return this._topBarHeight;
  }

  public get selectedTabContent(): string {
    return this._linksList.nativeElement.querySelectorAll('[ifw-top-bar-tab][ifw-selected]')[0]?.textContent ?? '';
  }

  public get linksListDisplayed(): boolean {
    return this._linksListDisplayed;
  }

  public toggleLinksList(): void {
    this._linksListDisplayed = !this._linksListDisplayed;
  }

  ngOnInit(): void {
    this.updateTopBarHeight();
  }

  ngAfterViewChecked(): void {
    if (this._topBarHeight !== this._elementRef.nativeElement.clientHeight) {
      requestAnimationFrame(() => {
        this.updateTopBarHeight();
      });
    }
  }

  @HostListener('window:resize')
  private onResize(): void {
    this.updateTopBarHeight();
  }

  private updateTopBarHeight(): void {
    this._topBarHeight = this._elementRef.nativeElement.clientHeight;
  }
}
