import {Component} from '@angular/core';

@Component({
  selector: 'button[ifw-icon-button]',
  templateUrl: './icon-button.component.html',
  styleUrls: [
    '../buttons.common.scss',
    './icon-button.component.scss',
  ],
})
export class IconButtonComponent {

}
