import {Component} from '@angular/core';

@Component({
  selector: 'input[type=text][ifw-text-field]',
  templateUrl: './text-field.component.html',
  styleUrls: [
    '../fields.common.scss',
    './text-field.component.scss',
  ],
})
export class TextFieldComponent {

}
