import {Component, OnInit} from '@angular/core';
import {AccommodationService} from './services/accommodation/accommodation.service';
import {AccommodationInterface} from './services/accommodation/accommodation.interface';

@Component({
  selector: 'ifw-reception',
  templateUrl: './reception.component.html',
  styleUrls: ['./reception.component.scss'],
})
export class ReceptionComponent implements OnInit {

  constructor(
    private readonly _accommodationService: AccommodationService,
  ) {
  }

  public get hotels(): Array<AccommodationInterface> {
    return this._accommodationService.hotels;
  }

  public get bedAndBreakfasts(): Array<AccommodationInterface> {
    return this._accommodationService.bedAndBreakfasts;
  }

  public get gites(): Array<AccommodationInterface> {
    return this._accommodationService.gites;
  }

  ngOnInit(): void {
  }

}
