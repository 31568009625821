<div class="desktopWrapper">
  <p [innerHTML]="format(accommodation?.place)"></p>
  <p [innerHTML]="format(accommodation?.distance)"></p>
  <p [innerHTML]="format(accommodation?.phone)"></p>
  <p [innerHTML]="format(accommodation?.address)"></p>
  <p [innerHTML]="format(accommodation?.capacity)"></p>
</div>

<ifw-expansion-panel class="mobileWrapper">
  <div ifw-expansion-panel-header
       class="mobileHeader">
    <p class="mobilePlace" [innerHTML]="format(accommodation?.place)"></p>
    <p class="mobileCapacity" [innerHTML]="format(accommodation?.capacity)"></p>
  </div>
  <div class="mobileBody">
    <div>
      <p class="mobilePhone" [innerHTML]="format(accommodation?.phone)"></p>
      <p class="mobileDistance" [innerHTML]="format(accommodation?.distance)"></p>
    </div>
    <p class="mobileAddress" [innerHTML]="format(accommodation?.address)"></p>
  </div>
</ifw-expansion-panel>
